import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Formik } from "formik";
import * as Yup from 'yup';
import UserPool from "../Integrations/Cognito/UserPool";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import DismissibleAlert from "../Components/DismissibleAlert";
import { useLocalStorage } from "../Hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";

function Signup() {
  const [, setEmail] = useLocalStorage("email", null);
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState('success');
  const [alertHeading, setAlertHeading] = useState('');
  const [alertContent, setAlertContent] = useState('');

  const dismissAlert = () => {
    setShowAlert(false);
  }

  const displayAlert = (title: string, message: string) => {
    setAlertHeading(title);
    setAlertContent(message);
    setShowAlert(true);
  }

  const displayErrorAlert = (title: string, message: string) => {
    setAlertVariant("danger");
    displayAlert(title, message)
  }

  const signupSchemaValidation = Yup.object().shape({
    email: Yup.string()
    .email("*Must be a valid email address")
    .required("*Email is required"),
    password: Yup.string()
    .required("*Password is required")
    .min(8, "*Password must be at least 8 characters long")
    .test("isValidPass", "Password must have at least one upper case, one lower case, a number and a symbol", (value, context) => {
      value = value ?? '';

      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasSymbol = /[!@#%&]/.test(value);
      let validConditions = 0;
      const numberOfMustBeValidConditions = 4;
      const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbol];
      conditions.forEach((condition) =>
        condition ? validConditions++ : null
      );
      if (validConditions >= numberOfMustBeValidConditions) {
        return true;
      }
      return false;
    })
  });

  const signup = (values: any, {setSubmitting, resetForm}: {setSubmitting: any, resetForm: any}) => {
    setSubmitting(true);

    const username = values.email.replace(/@/, "-at-");
    const attributeList = [];
    attributeList.push(
      new CognitoUserAttribute({ Name: 'email', Value: values.email })
    );

    UserPool.signUp(username, values.password, attributeList, [], (err, data) => {
      if (err) {
        displayErrorAlert("Sorry, we've failed to create your account", err.message);
      } else {
        setEmail(values.email);
        resetForm();
        navigate("/confirm-account");
      }
      setSubmitting(false);
    });
  };

  return(
    <div id="signup-page">
      <div className="container">
        <h1>Sign up</h1>

        <DismissibleAlert showAlert={showAlert} variant={alertVariant} heading={alertHeading} content={alertContent} onClose={dismissAlert} />

        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={signupSchemaValidation}
          onSubmit={signup}
        >
          {( {values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  className={touched.email && errors.email ? "error" : ""}
                />
                {touched.email && errors.email ? (
                  <div className="error-message">{errors.email}</div>
                ): null}
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  className={touched.password && errors.password ? "error" : ""}
                />
                {touched.password && errors.password ? (
                  <div className="error-message">{errors.password}</div>
                ): null}
              </Form.Group>

              <Button variant="primary" type="submit" disabled={isSubmitting}>Sign up</Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Signup;
