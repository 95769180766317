import React, { useEffect, useState } from "react";
import { Project, GetProjects, GetProjectsResponse, RequestError } from "../Integrations/Backend/Projects";

function Projects() {
  const projectsSuccessFetch = (response: GetProjectsResponse) => {
    setProjects(response.data);
  }

  const projectsFailureFetch = (response: RequestError) => {
    setError(response.message);
  }

  const [projects, setProjects] = useState<Project[]>([]);
  const [error, setError] = useState('');

  useEffect(() => {
    GetProjects(projectsSuccessFetch, projectsFailureFetch);
  }, []);

  const ErrorDisplay = () => {
    return <h2>{error}</h2>
  }
  return (
    <>
      <h1>Projects</h1>
      { error?.length > 0 && ErrorDisplay() }

      {projects.map((project) => {
        return <p key={project.id}>{project.name}</p>
      })}
    </>
  );
}

export default Projects;
