import { AuthenticationDetails, CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import UserPool from "./UserPool";

export const getUserAccessToken = async () => {
  return new Promise((res) => {
    UserPool.getCurrentUser()?.getSession((error: Error | null, currentSession: CognitoUserSession | null) => {
      if (error != null) {
        res('');
      }

      res(currentSession?.getAccessToken().getJwtToken());
    });
  });
};

export const getUsername = (): String => {
  return UserPool.getCurrentUser()?.getUsername() || '';
}

export const cognitoConfirmAccount = async (email: string, confirmationCode: string) => {
  const username = email.replace(/@/, "-at-");

  const user = new CognitoUser({
    Username: username,
    Pool: UserPool
  });

  return new Promise((resolve, reject) => {
    const callback = (err: any, result: any) => {
      if (err) {
        reject(err);
        return;
      }

      resolve(result);
    }
    user.confirmRegistration(confirmationCode, true, callback);
  })
}

export const cognitoLogin = async (email: string, password: string) => {
  return new Promise((resolve, reject) => {
    const username = email.replace(/@/, "-at-");

    const user = new CognitoUser({
      Username: username,
      Pool: UserPool
    });

    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password
    });

    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => {
        console.log('login failed', err);
        reject(err);
      },
      newPasswordRequired: (data) => {
        console.log('new password required', data);
        reject(data);
      }
    });
  });
}

export const logout = async () => {
  return new Promise((resolve) => {
    UserPool.getCurrentUser()?.signOut(() => {
      resolve('logged out');
    });
  })
}
