import { Formik } from "formik";
import React, { useState } from "react";
import { Form, Container, Button, InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const DatePickerField = ({ name, value, onChange }: {name: string, value: Date | null, onChange: any}) => {
  return (
    <DatePicker
      selected={(value && new Date(value)) || null}
      onChange={val => {
        onChange(name, val);
      }}
      isClearable
    />
  );
};

const FormInput = ({ formSubmit }: { formSubmit: any }) => {
  return (
    <Formik
      initialValues={{ expiryDate: null, patIdentifier: "", projectId: "" }}
      onSubmit={formSubmit}
    >
      {( {values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="pat-identifier">Custom identifier</Form.Label>
            <Form.Control
              id="pat-identifier"
              type="text"
              name="patIdentifier"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.patIdentifier}
              placeholder="Any custom identifier for you to recognize this token"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="project-selector">Project</Form.Label>
            <Form.Control
              as="select"
              id="project-selector"
              placeholder="Select project"
              onChange={handleChange}
              value={values.projectId}
              name="projectId"
            >
              <option value="">Please select the project you want to create the token for</option>
              <option value="project_id_1">Project 1</option>
              <option value="project_id_2">Project 2</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="expiry-date">Expires at</Form.Label>
            <DatePickerField
              name="expiryDate"
              value={values.expiryDate}
              onChange={setFieldValue}
            />
          </Form.Group>

          <Button variant="primary" type="submit" disabled={isSubmitting}>Create token</Button>
        </Form>
      )}
    </Formik>
  )
}

const FormOutput = ({ token }: { token: string }) => {
  const [copyIcon, setCopyIcon] = useState("bi bi-clipboard");

  const copyToClipboard = () => {
    navigator.clipboard.writeText(token);
    setCopyIcon("bi bi-clipboard-check")
  }

  return (
    <>
      <p>Please copy the token and store it in a safe place. Once closed, this token is not recoverable</p>
      <InputGroup className="mb-3">
        <InputGroup.Text id="basic-addon1">
          <i className={copyIcon} onClick={copyToClipboard}></i>
        </InputGroup.Text>
        <Form.Control type="text" defaultValue={token} readOnly />
      </InputGroup>
    </>
  )
}

function TokenForm() {
  const [createdToken, setCreatedToken] = useState<string>();

  const submitCreateToken = (values: any, { setSubmitting, resetForm }: {setSubmitting: any, resetForm: any}) => {
    setSubmitting(true);
    console.log(values);
    console.log('Sending request');
    setCreatedToken('bananas123');
    setSubmitting(false);
  }

  return (
    <Container fluid>
      { !createdToken && <FormInput formSubmit={submitCreateToken}/>}
      { createdToken && <FormOutput token={createdToken} /> }
    </Container>
  );
}

export default TokenForm;
