import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Formik } from "formik";
import DismissibleAlert from "../Components/DismissibleAlert";
import { useLocalStorage } from "../Hooks/useLocalStorage";
import { cognitoConfirmAccount } from "../Integrations/Cognito/Auth";
import { useNavigate } from "react-router-dom";

function ConfirmAccount() {
  const [email,] = useLocalStorage("email", null);
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState('success');
  const [alertHeading, setAlertHeading] = useState('');
  const [alertContent, setAlertContent] = useState('');

  const dismissAlert = () => {
    setShowAlert(false);
  }

  const displayAlert = (title: string, message: string) => {
    setAlertHeading(title);
    setAlertContent(message);
    setShowAlert(true);
  }

  const displayErrorAlert = (title: string, message: string) => {
    setAlertVariant("danger");
    displayAlert(title, message)
  }

  const displaySuccessAlert = (title: string, message: string) => {
    setAlertVariant("success");
    displayAlert(title, message)
  }

  const confirmAccount = (values: any, {setSubmitting, resetForm}: {setSubmitting: any, resetForm: any}) => {
    setSubmitting(true);

    const confirmationCode = values.confirmationCode;
    cognitoConfirmAccount(email, confirmationCode).then(() => {
      resetForm();
      displaySuccessAlert("Account confirmed", "Please Sign In");
      setTimeout(() => {
        navigate("/signin");
      }, 1000);
    }).catch((error) => {
      resetForm();
      displayErrorAlert("Sorry, something went wrong", error);
    }).finally(() => {
      setSubmitting(false);
    });
  };

  return(
    <div id="confirmation-page">
      <div className="container">
        <h1>Confirm Account</h1>

        <DismissibleAlert showAlert={showAlert} variant={alertVariant} heading={alertHeading} content={alertContent} onClose={dismissAlert} />

        <Formik
          initialValues={{ confirmationCode: "" }}
          onSubmit={confirmAccount}
        >
          {( {values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formConfirmationAccount">
                <Form.Label>Confirmation Code</Form.Label>
                <Form.Control
                  type="string"
                  name="confirmationCode"
                  placeholder="Enter confirmation code received by email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmationCode}
                />
              </Form.Group>

              <Button variant="primary" type="submit" disabled={isSubmitting}>Confirm Account</Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ConfirmAccount;
