import axios from 'axios';

import { getUserAccessToken } from './Cognito/Auth';

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'http://corgi-tech-debt-load-balancer-7752797bc8b5f9cb.elb.ap-southeast-1.amazonaws.com'

const api = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

api.interceptors.request.use(
  async config => {
    config.headers = config.headers ?? {};
    const jwtToken = await getUserAccessToken();
    config.headers['Authentication'] = String(jwtToken);
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);


export default api;
